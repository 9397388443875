import React from 'react'
import styled from 'styled-components'

export const Blog = props => {
  const posts = props.data.allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .slice(0, 3)
    .map(edge => (
      <div>
        >{' '}
        <a key={edge.node.id} href={edge.node.frontmatter.path}>
          {edge.node.frontmatter.title}
        </a>
      </div>
    ))

  return (
    <Wrapper>
      <div className="posts-container">
        {posts}
        <div>
          > <a href="/blog">Older posts...</a>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  align-items: center;

  h2 {
    font-size: 3em;
  }

  .posts-container {
    margin: auto;
    width: 760px;
    padding: 20px;
    max-width: 100%;

    a {
      color: #145a6f;
    }
    a:hover {
      color: rgb(0, 182, 214);
    }
  }
`
export default Blog
