import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import beforeImg from '../../designs/dist/img/before-img.png'

export const CaseStudy = () => (
  <Wrapper>
    <div>
      <Link to="/case-study">
        <p>
          Learn how we helped our client optimize their application for
          increasing traffic. With extensive test scenarios and an intensive
          stress-testing solution, we delivered them a successful world-class
          app.
        </p>
        <div style={{ backgroundColor: 'white', padding: 10 }}>
          <img src={beforeImg} alt="before-img" />
        </div>
      </Link>
    </div>
  </Wrapper>
)

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.11s;
  background-color: white;
  padding: 20px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #0094cf;
    transition-duration: 0.31s;

    a {
      color: inherit;
      text-decoration: none;
    }

    div p {
      color: white;
    }
  }
`

export default CaseStudy
