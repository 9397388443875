import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PropTypes from 'prop-types'
import { OpenSource } from '../components/Resources/OpenSource'
import { CaseStudy } from '../components/Resources/CaseStudy'
import { ResourcesSection } from '../components/Resources/ResourcesSection'
import Blog from '../components/Resources/Blog'
import styled from 'styled-components'

function WhitePapers() {
  return (
    <Wrapper>
      <div className="papers-container">
        <div>
          >{' '}
          <a
            href={
              '/whitepapers/TheBrain-5WaysMobileAppCanImproveYourBusiness.pdf'
            }
            download
          >
            5 Ways Mobile App Can Improve Your Business
          </a>
        </div>
        <div>
          >{' '}
          <a href={'/whitepapers/TheBrain-BuildingASuccessfulApp.pdf'} download>
            Building a Successful App
          </a>
        </div>
      </div>
    </Wrapper>
  )
}

class Resources extends React.Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    return (
      <Layout menuVisible={this.state.menuVisible} toggleMenu={this.toggleMenu}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#001d29',
          }}
        >
          <span
            className={'h1'}
            style={{ fontSize: '400%', marginTop: 100, color: 'white' }}
          >
            resources
          </span>
        </div>
        <ResourcesSection title="blog" inverted>
          <Blog data={this.props.data} />
        </ResourcesSection>
        <ResourcesSection title="whitepapers">
          <WhitePapers />
        </ResourcesSection>
        <ResourcesSection title="Case Studies" inverted>
          <CaseStudy />
        </ResourcesSection>
        <OpenSource />
      </Layout>
    )
  }
}

Resources.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
  }).isRequired,
}

export default Resources

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 120)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            author
            cta
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 740) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  background-color: white;
  margin: 0;
  padding: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .papers-container {
    margin: auto;
    width: 760px;
    padding: 20px;
    max-width: 100%;

    a {
      color: #145a6f;
      width: 760px;
      max-width: 100%;
    }

    a:hover {
      color: rgb(0, 182, 214);
    }
  }
`
