import React from 'react'
import styled from 'styled-components'
import soImg from '../../designs/dist/img/so-img.png'
import cImg1 from '../../designs/dist/img/c-img1.png'
import cImg4 from '../../designs/dist/img/c-img4.png'
import cImg2 from '../../designs/dist/img/c-img2.png'
import cImg3 from '../../designs/dist/img/c-img3.png'

export const ResourcesSection = props => (
  <>
    <Wrapper className="section-source" {...props}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 blue-holder d-flex justify-content-center align-items-center">
            <span className="h1">{props.title}</span>
          </div>
          <div className="col-12 col-lg-6 dark-holder">{props.children}</div>
        </div>
      </div>
    </Wrapper>
  </>
)

const Wrapper = styled.section`
 @media (max-width: 990px){
 div.blue-holder {
   background-color: ${props => (props.inverted ? 'white' : '#0094cf')};

   };
 }
//
// @media (max-width: 990px){
//   .dark-holder p,
//   .dark-holder p {
//      color: ${props => (props.inverted ? '#0094cf' : 'white')};
//   }
// }

@media (max-width: 990px){
.dark-holder:after {
    background-color: ${props => (props.inverted ? 'white' : '#0094cf')};
    background-color: ${props =>
      props.bgColor ? props.bgColor : props.inverted ? 'white' : '#0094cf'};
  }
}
    margin: 0;
    padding: 0;
  .blue-holder {
    height: 200px;
  }
  
  span.h1 {
    color: ${props => (props.inverted ? '#0094cf' : 'white')};
    margin: 0
  }
  
  .dark-holder:after {
    content: '';
    position: absolute;
    z-index: 0;
    width: 50000px;
    height: 100%;
    // background-color: ${props => (props.inverted ? 'white' : '#0094cf')};
    background-color: ${props =>
      props.bgColor ? props.bgColor : props.inverted ? 'white' : '#0094cf'};
    top: 0;
    left: -50000px;
  }
  
  .dark-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
  
  .dark-holder p, .dark-holder ul {
    margin-bottom: 0;
  }
 
`
