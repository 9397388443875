import React from 'react'
import styled from 'styled-components'
import soImg from '../../designs/dist/img/so-img.png'
import cImg1 from '../../designs/dist/img/c-img1.png'
import cImg4 from '../../designs/dist/img/c-img4.png'
import cImg2 from '../../designs/dist/img/c-img2.png'
import cImg3 from '../../designs/dist/img/c-img3.png'

export const OpenSource = () => (
  <>
    <Wrapper className="section-source" id={'openSource'}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 blue-holder d-flex justify-content-center align-items-center">
            <img src={soImg} alt="so-img" />
          </div>
          <div className="col-12 col-lg-6 dark-holder">
            <h2 className="h1 section-title white">
              <span className="white-effect">Open</span>
              <br />
              <span>Source</span>
            </h2>
            <p>
              We believe in the power of open source software and give back to
              the community by working on these testing tools
            </p>
          </div>
        </div>
      </div>
      <section className="section-code pt-5 text-center">
        <div className="container">
          <label className="pb-5">
            TheBrain is the lead contributor on several industry-standard
            automated testing code bases.
          </label>
          <div className="row">
            <div className="col-12 col-md-4">
              <div
                className="code-holder d-flex justify-content-end align-items-center flex-column"
                style={{ backgroundImage: `url(${cImg1})` }}
              >
                <label className="section-title white">chimpy.js</label>
                <p>Test runner tailored to Meteor.js applications</p>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={cImg4} alt="c-img4" className="mr-1" />
                  <a href="https://github.com/TheBrainFamily/chimpy">CODE</a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div
                className="code-holder d-flex justify-content-end align-items-center flex-column"
                style={{ backgroundImage: `url(${cImg2})` }}
              >
                <label className="section-title white">wait-for-expect</label>
                <p>Wait for expectation to be true</p>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={cImg4} alt="c-img4" className="mr-1" />
                  <a href="https://www.npmjs.com/package/wait-for-expect">
                    CODE
                  </a>
                </span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div
                className="code-holder d-flex justify-content-end align-items-center flex-column"
                style={{ backgroundImage: `url(${cImg3})` }}
              >
                <label className="section-title white">
                  cypress-cucumber-preprocessor
                </label>
                <p>Cucumber/gherkin syntax with cypress.io</p>
                <span className="d-flex justify-content-center align-items-center">
                  <img src={cImg4} alt="c-img4" className="mr-1" />
                  <a href="https://github.com/TheBrainFamily/cypress-cucumber-preprocessor">
                    CODE
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  </>
)

const Wrapper = styled.section`
  padding: 0;
  .dark-holder {
    width: 100%;
    min-height: 356px;
    position: relative;
    padding: 65px 120px 65px 90px;
    background-color: #001d29;
  }

  img {
    z-index: 1;
  }
  .dark-holder:after {
    content: '';
    position: absolute;
    z-index: 0;
    width: 50000px;
    height: 100%;
    background-color: #0094cf;
    top: 0;
    left: -50000px;
  }

  .dark-holder p {
    font-size: 87.5%;
    color: #fff;
  }
`
